'use client';
//  Video is meant to be embedded within a  molecule.
// It is not meant as a standalone - its SEO properties come from .
import { gsap } from 'gsap';
import noop from 'lodash/noop';
import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { OnProgressProps } from 'react-player/base';
import ReactPlayer, { FileConfig } from 'react-player/file';

import Image from '~/components/atoms/Image/Image';
import Observer from '~/components/atoms/Observer/Observer';
import useUIStore from '~/state/ui';
import { cn, useScrollProgress } from '~/utils';
import isBreakpointOrGreater from '~/utils/isBreakpointOrGreater';
import { getItem } from '~/utils/sessionStorage';

import Controls from './Controls/Controls';
import { ControlPanelRef } from './Controls/Controls.types';
import VideoState from './utils/VideoState';
import styles from './Video.module.css';
import {
  ElementWithFullScreen,
  SESSION_STORAGE_MUTE,
  VideoProps,
  VideoRef,
  VideoStateProps,
} from './Video.types';

const Video = (
  {
    // applies to all videos
    alt,
    ariaHidden = false,
    aspectRatio,
    disablePictureInPicture = true,
    contain = false,
    details,
    isLooping,
    src,
    forceIsInView = false,
    playEndlessly = false,
    forceUseMp4 = false,
    role = 'application',
    shouldFocusControls = false,
    loopLength,
    className,
    displayStaticThumbnail = true,
    thumbnail,
    thumbnailClassName,
    imageAspectRatio,
    imageColumns,

    // determined by whether it's a bg or playable video
    controls = false,
    isMuted: isInitiallyMuted = false,
    willAutoplay = true,
    playsInline = true,

    //callbacks
    onVideoReady = noop,
    onEnded = noop,
    onClick = noop,
  }: VideoProps,
  ref: ForwardedRef<VideoRef>,
) => {
  const hlsSupport = useUIStore((state) => state.hlsSupport);
  const breakpoint = useUIStore((state) => state.breakpoint);
  const $wrapper = useRef<HTMLDivElement>(null);
  const playerRef = useRef<ReactPlayer>(null);
  const controlsRef = useRef<ControlPanelRef>(null);
  const [isVideoInView, setIsVideoInView] = useState(forceIsInView);
  const [canLoadVideo, updateCanLoadVideo] = useState<boolean>(false);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [duration, setDuration] = useState(0);
  const videoState = useRef<VideoStateProps>();
  // display static thumbnail if one exists, video has controls (is a playable video and not a bg video, and has not been interacted with)

  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState<boolean>(
    isInitiallyMuted || !!getItem(SESSION_STORAGE_MUTE),
  );

  const setCurrentFullscreenVideoSrc = useUIStore(
    (state) => state.setCurrentFullscreenVideoSrc,
  );

  useEffect(() => {
    setIsVideoInView(forceIsInView);
  }, [forceIsInView]);

  const id = useId();

  useEffect(() => {
    videoState.current = new VideoState(playerRef, $wrapper, isMuted);

    let unsubscribe = noop;

    if (videoState.current) {
      unsubscribe = videoState.current.subscribe({
        id,
        onMute,
        onUnmute,
      });
    }

    return () => {
      unsubscribe({ id });
    };
  }, [id, isMuted]);

  const getInternalPlayer = () => {
    if (playerRef.current && playerRef.current instanceof ReactPlayer) {
      return playerRef.current?.getInternalPlayer();
    } else if (playerRef.current) {
      return playerRef.current;
    }
    return null;
  };

  const onProgress = (progress: OnProgressProps) => {
    const { playedSeconds } = progress;
    if (loopLength && playedSeconds > loopLength) {
      restart(isPlaying);
    }
    videoState.current?.onProgress?.(progress);
  };

  const onDuration = (duration: number) => {
    setDuration(duration);
  };

  const onPlay = () => {
    setIsPlaying(true);
    videoState.current?.onPlay?.();
  };

  const onPause = () => {
    setIsPlaying(false);
    videoState.current?.onPause?.();
  };

  const onMute = () => {
    setIsMuted(true);
  };

  const onUnmute = () => {
    setIsMuted(false);
  };

  // when the toggle mute button is clicked, the volume of the video could have been manually set to 0 by the muteSmoothly function, so it needs to be reset here just in case.
  useEffect(() => {
    const internalPlayer = getInternalPlayer();
    if (internalPlayer) internalPlayer.volume = isMuted ? 0 : 1;
  }, [isMuted]);

  const restart = useCallback((autoplay = true) => {
    videoState.current?.restart?.(autoplay);
  }, []);

  useEffect(() => {
    if (!isVideoReady && isPlaying) {
      setIsVideoReady(true);
      if (onVideoReady) {
        onVideoReady(getInternalPlayer() as HTMLVideoElement);
      }
    }
  }, [isPlaying, isVideoReady, onVideoReady]);

  const onScrollProgress = useCallback(
    (progress: number, isInView: boolean) => {
      const internalPlayer = getInternalPlayer();
      if (internalPlayer) {
        if (willAutoplay) {
          if (playEndlessly) {
            if (internalPlayer.paused === true) {
              if (progress > 0.01) {
                setIsVideoInView(true);
                videoState.current?.play?.();
              } else {
                // Still restart and pause the video if we scrolled above it
                restart(false);
              }
            }
            return;
          }

          if (
            isInView &&
            progress > 0.3 &&
            progress < 0.5 &&
            internalPlayer.paused === true &&
            (!internalPlayer.ended || isLooping)
          ) {
            setIsVideoInView(true);
            videoState.current?.play?.();
          }

          // If we scrolled past it
          if (!isInView) {
            restart(false);
          }
        }
      }
    },
    [playEndlessly, willAutoplay, isLooping, restart],
  );

  // add Event listener for fullscreen change
  useEffect(() => {
    const element = $wrapper.current as ElementWithFullScreen;

    function fullscreenChanged() {
      if (document.fullscreenElement) {
        setCurrentFullscreenVideoSrc(src);
      } else {
        // Add small delay to prevent triggering scroll event during the transition from fullscreen
        // to normal (which triggers the modal to close completely)
        setTimeout(() => {
          setCurrentFullscreenVideoSrc(null);
        }, 100);
      }
    }
    element.addEventListener('fullscreenchange', fullscreenChanged);

    return () => {
      element.removeEventListener('fullscreenchange', fullscreenChanged);
    };
  }, [$wrapper, src, setCurrentFullscreenVideoSrc]);

  useScrollProgress($wrapper, onScrollProgress);

  useImperativeHandle(
    ref,
    () => ({
      get $wrapper() {
        return $wrapper;
      },
      get $player() {
        return (getInternalPlayer() as HTMLVideoElement) || null;
      },
      play: () => {
        videoState.current?.play?.();
      },
      pause: () => {
        videoState.current?.pause?.();
      },
      seekTo: (cue: number) => {
        videoState.current?.seekTo?.(cue);
      },
      restart,
      setVolume: (volume: number) => {
        const internalPlayer = getInternalPlayer();
        if (internalPlayer) {
          internalPlayer.volume = volume;
        }
      },
      muteSmoothly: (duration?: number) => {
        const internalPlayer = getInternalPlayer();
        if (internalPlayer) {
          gsap.to(internalPlayer, {
            volume: 0,
            duration: duration || 0.4,
            onComplete: () => {
              // changing volume doesnt change muted state, so before to set this too for mute icon to change
              videoState.current?.forceMute?.();
            },
          });
        }
      },
      unmuteSmoothly: (duration?: number) => {
        const internalPlayer = getInternalPlayer();
        // only unmute the mute property hasn't been set in session storage
        if (isMuted && !getItem(SESSION_STORAGE_MUTE)) {
          if (internalPlayer) {
            if (duration === 0) {
              gsap.set(internalPlayer, {
                volume: 1,
                onComplete: () => {
                  // changing volume doesnt change muted state, so before to set this too for mute icon to change
                  videoState.current?.forceUnmute?.();
                },
              });
            } else {
              gsap.fromTo(
                internalPlayer,
                { volume: 0 },
                {
                  volume: 1,
                  duration: duration || 0.4,
                  onComplete: () => {
                    videoState.current?.forceUnmute?.();
                  },
                },
              );
            }
          }
        }
      },
      forceMute: () => {
        videoState.current?.forceMute?.();
      },
      focusControls: () => {
        if (controlsRef.current) {
          controlsRef.current.focus();
        }
      },
      toggleFullscreen: () => {
        videoState.current?.toggleFullscreen?.();
      },
    }),
    [isMuted, restart],
  );

  // Because react-player doesn't support SSR
  const [isBrowser, setIsBrowser] = useState(false);
  useEffect(() => {
    setIsBrowser(true);
  }, []);

  const fileConfig: FileConfig = {
    forceHLS: !hlsSupport,
    hlsOptions: {
      testBandwidth: false,
      startLevel: 4,
      capLevelToPlayerSize: true,
      maxMaxBufferLength: 2,
    },
  };

  const isBpGreaterThanMobile = breakpoint
    ? isBreakpointOrGreater(breakpoint, 'md')
    : false;

  return (
    <Observer
      options={{ rootMargin: '150% 0%', detectExit: true }}
      callback={() => updateCanLoadVideo(true)}
      onExit={() => updateCanLoadVideo(false)}
      className={cn(styles.wrapper, controls && styles.hasControls, className)}
      ref={$wrapper}
      {...(typeof aspectRatio === 'number'
        ? {
            style: {
              '--default-aspect-ratio': aspectRatio,
            } as CSSProperties,
          }
        : {})}
    >
      {thumbnail && (
        <div aria-hidden={true}>
          <Image
            source={thumbnail}
            className={cn(
              styles.thumbnail,
              displayStaticThumbnail && styles.shown,
              thumbnailClassName,
            )}
            aspectRatio={imageAspectRatio}
            columns={imageColumns}
            quality={willAutoplay ? 30 : 100}
          />
        </div>
      )}
      {isBrowser && canLoadVideo && (
        <>
          <ReactPlayer
            aria-hidden={ariaHidden}
            role={role}
            aria-label={alt}
            ref={playerRef as RefObject<ReactPlayer>}
            // React-player classNames doesn't get updated during state change
            className={cn(styles.video, contain ? '' : styles.cover)}
            style={{
              opacity: !isVideoReady ? 0 : 1,
            }}
            config={!forceUseMp4 ? fileConfig : undefined}
            controls={controls && !isBpGreaterThanMobile}
            height="100%"
            loop={isLooping}
            muted={isMuted}
            playing={isVideoInView && willAutoplay}
            playsinline={playsInline}
            disablePictureInPicture={disablePictureInPicture}
            url={src}
            width="100%"
            onDuration={onDuration}
            onPause={onPause}
            onPlay={onPlay}
            onProgress={onProgress}
            // progress needs to be reduced only when the controls are displayed
            progressInterval={controls && isBpGreaterThanMobile ? 100 : 1000}
            onEnded={onEnded}
            onClick={onClick}
          />
          {controls && isBpGreaterThanMobile && (
            <Controls
              ref={controlsRef}
              videoState={videoState}
              details={details}
              duration={duration}
              isMuted={isMuted}
              isPlaying={isPlaying}
              shouldFocus={shouldFocusControls}
            />
          )}
        </>
      )}
    </Observer>
  );
};

export default forwardRef(Video);
